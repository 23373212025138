// Auto-generated API route constants
// Do not modify directly

// CAMPAIGNS ROUTES
export const CAMPAIGNS_API_ROUTE = '/api/campaigns';
export const CAMPAIGNS_CREATE_API_ROUTE = '/api/campaigns/create';
export const CAMPAIGNS_DELETE_API_ROUTE = '/api/campaigns/delete';
export const CAMPAIGNS_INVITE_API_ROUTE = '/api/campaigns/invite';
export const makeCampaignsIdApiRoute = (id: string) =>
  '/api/campaigns/:id'.replace(':id', id);
export const makeCampaignsIdEventsApiRoute = (id: string) =>
  '/api/campaigns/:id/events'.replace(':id', id);
export const makeCampaignsUserIdApiRoute = (id: string) =>
  '/api/campaigns/user/:id'.replace(':id', id);

// EVENT-TEMPLATES ROUTES
export const EVENT_TEMPLATES_API_ROUTE = '/api/event-templates';
export const EVENT_TEMPLATES_BULK_CREATE_API_ROUTE =
  '/api/event-templates/bulk-create';
export const EVENT_TEMPLATES_CREATE_API_ROUTE = '/api/event-templates/create';
export const makeEventTemplatesIdApiRoute = (id: string) =>
  '/api/event-templates/:id'.replace(':id', id);

// EVENTS ROUTES
export const EVENTS_TRIGGER_API_ROUTE = '/api/events/trigger';

// HOUSES ROUTES
export const HOUSES_API_ROUTE = '/api/houses';
export const HOUSES_EDIT_API_ROUTE = '/api/houses/edit';
export const makeHousesIdApiRoute = (id: string) =>
  '/api/houses/:id'.replace(':id', id);
export const makeHousesIdPeopleApiRoute = (id: string) =>
  '/api/houses/:id/people'.replace(':id', id);
export const makeHousesIdRelationshipsApiRoute = (id: string) =>
  '/api/houses/:id/relationships'.replace(':id', id);

// MAP-NODES ROUTES
export const MAP_NODES_CREATE_API_ROUTE = '/api/map-nodes/create';
export const makeMapNodesIdApiRoute = (id: string) =>
  '/api/map-nodes/:id'.replace(':id', id);
export const makeMapNodesIdDeleteApiRoute = (id: string) =>
  '/api/map-nodes/:id/delete'.replace(':id', id);
export const makeMapNodesIdUpdateApiRoute = (id: string) =>
  '/api/map-nodes/:id/update'.replace(':id', id);

// NODES ROUTES
export const NODES_API_ROUTE = '/api/nodes';
export const NODES_SEARCH_API_ROUTE = '/api/nodes/search';
export const NODES_UPDATE_RELATIONSHIP_API_ROUTE =
  '/api/nodes/update-relationship';
export const makeNodesIdApiRoute = (id: string) =>
  '/api/nodes/:id'.replace(':id', id);
export const makeNodesIdRelationshipsApiRoute = (id: string) =>
  '/api/nodes/:id/relationships'.replace(':id', id);

// PEOPLE ROUTES
export const PEOPLE_CREATE_API_ROUTE = '/api/people/create';
export const PEOPLE_GENERATE_API_ROUTE = '/api/people/generate';

// PLACES ROUTES
export const PLACES_GENERATE_API_ROUTE = '/api/places/generate';

// RESOLVE-AI-EVENT ROUTES
export const RESOLVE_AI_EVENT_API_ROUTE = '/api/resolve-ai-event';

// SESSION-RECAPS ROUTES
export const SESSION_RECAPS_API_ROUTE = '/api/session-recaps';
export const makeSessionRecapsIdApiRoute = (id: string) =>
  '/api/session-recaps/:id'.replace(':id', id);

// STATIC-MAPS ROUTES
export const STATIC_MAPS_API_ROUTE = '/api/static-maps';
export const STATIC_MAPS_CREATE_API_ROUTE = '/api/static-maps/create';
export const makeStaticMapsIdApiRoute = (id: string) =>
  '/api/static-maps/:id'.replace(':id', id);

// TRIGGER-AI-EVENT ROUTES
export const TRIGGER_AI_EVENT_API_ROUTE = '/api/trigger-ai-event';

// USERS ROUTES
export const USERS_ME_API_ROUTE = '/api/users/me';
export const USERS_SEARCH_API_ROUTE = '/api/users/search';
export const makeUsersIdApiRoute = (id: string) =>
  '/api/users/:id'.replace(':id', id);
